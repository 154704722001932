.tablist {
    display: flex;
    gap: .5em;
    margin-top: 1em;
    min-width: 100%;
    width: fit-content;
}
.tab-title {
    list-style: none;
}
.tab-title button {
    background-color: inherit;
    border: 2px solid rgb(50, 50, 50);
    border-radius: 1ex 1ex 0 0;
    border-bottom: 0;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: 1.2rem;
    margin: 0;
    padding: 0.5ex 0.8rem 0;
    line-height: normal;
}
.tab-title.active button {
    background-color: rgb(50, 50, 50);
}
@media print {
    .tab-title.active button {
        font-weight: bold;
    }
}
