.stats {
    width: 90vw;
    padding: 0 5vw 0;
    overflow: auto;
    box-sizing: content-box;
}
.stats-input {
    min-width: 90vw;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    gap: 1em;
}
.stats-urls {
    width: 100%;
}
.stats-filter {
    display: inline-block;
    break-inside: avoid;
}
.stats-filter input {
    margin: 0 0 0 1em;
}
.stats-table {
    width: 100%;
    border-collapse: collapse;
    line-height: 1.3;
    padding-top: 1ex;
}
.stats-table th {
    text-align: left;
    min-width: 1em;
}
.stats-table th, .stats-table td {
    padding: 0 2px;
}
.stats-table tr:nth-child(even), .stats-table tr:has(th) {
    background-color: rgb(50, 50, 50);
}
.stats-tiers {
    background-color: rgb(50, 50, 50);
    width: 100%;
}
.stats-tiers td:first-child {
    background-color: var(--base-colour);
    background-color: var(--colour-mix, var(--base-colour));
    color: rgb(50, 50, 50);
    font-family: Arial, Helvetica, sans-serif;
    min-height: 80px;
    width: 100px;
    text-align: center;
}
.stats-tiers td:last-child {
    display: flex;
    flex-wrap: wrap;
}
.stats-tier {
    font-size: 150%;
    margin-top: 1em;
    margin-bottom: 0.5em;
}
.stats-img {
    flex: initial;
    max-width: 125px;
    max-height: 125px;
    width: auto;
    height: auto;
    object-fit: cover;
}
@media print {
    .no-print {
        display: none;
    }
    body {
        margin-top: 1ex;
    }
    .stats {
        width: 100%;
    }
    img {
        break-inside: avoid-page;
    }
    tr {
        break-after: auto-page;
    }
}
