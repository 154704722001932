* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    pointer-events: auto;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body {
    background-color: rgb(36, 36, 36);
    color: white;
    display: flex;
    flex-direction: column;
    font-family: 'Courier New', Courier, monospace;
    min-height: 100vh;
}

a {
    color: aqua;
    text-decoration: underline;
    cursor: pointer;
}

a:visited {
    color: pink;
}
